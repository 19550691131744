<template>
  <div class="login_form_wrap">
    <Form @submit="onSubmit" class="form_list" validate-trigger="onSubmit">
      <div class="no_code">{{ $t('login.noCode') }}1</div>
      <Field v-model="formValue[item.key]" :placeholder="$t(item.label)" v-for="(item, index) in formList"
        :key="`form_item_${index}`" :rules="item.rules || []" :maxlength="item.length || 99" :type="item.type || 'text'"
        :readonly="item.readonly || false" :disabled="item.disabled || false">
        <template #left-icon>
          <van-icon :name="item.icon" class="icon" v-if="item.icon" />
          <img class="img" v-if="item.img" :src="item.img" alt="">
        </template>
        <template #right-icon v-if="item.isPassword">
          <i class="iconfont" :class="item.type === 'password'
            ? 'icon-yanjing_xianshi_o'
            : 'icon-yanjing_yincang_o'
            " @click="showPassword(item, index)"></i>
        </template>
      </Field>

      <slot name="read">
        <Checkbox v-model="read" shape="square" checked-color="#3E8597">
          {{ $t(readText) }}
        </Checkbox>
      </slot>
      <slot name="confirm">
        <div class="btns">
          <Btn round block native-type="submit" color="#16a1ba" class="confirm">
            {{ $t(confirmText) }}
          </Btn>
        </div>
      </slot>
      <slot name="cancel">
        <div class="btn_wrap">
          <div class="cancel" @click="handleClose">
            {{ $t(cancelText) }}
          </div>
        </div>
      </slot>
    </Form>
  </div>
</template>

<script>
import {
  Icon,
  Image as Img,
  Field,
  Form,
  Button as Btn,
  Checkbox,
  Toast,
} from "vant";
export default {
  props: {
    readText: {
      type: String,
    },
    confirmText: {
      type: String,
      default: "Login.Forgot.confirm",
    },
    cancelText: {
      type: String,
    },
    needRead: {
      type: Boolean,
      default: false,
    },
    formList: {
      type: Array,
      default: () => [],
    },
    getRemember: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    [Icon.name]: Icon,
    Img,
    Field,
    Form,
    Btn,
    Checkbox,
  },
  data() {
    return {
      formValue: {},
      read: true,
    };
  },
  created() {
    this.initValue();
    const { id: fromid } = this.$route.query;
    if (fromid) {
      this.$set(this.formValue, "fromid", fromid);
    }
    if (this.getRemember) {
      let storePwd = localStorage.getItem("the-pwd-obj");
      if (storePwd) {
        storePwd = JSON.parse(storePwd);
        this.$set(this.formValue, "userphone", storePwd.userphone);
        this.$set(this.formValue, "userpass", storePwd.userpass);
      }
    }
  },
  watch: {
    formList() {
      this.initValue();
    },
  },
  methods: {
    initValue() {
      for (let index = 0; index < this.formList.length; index++) {
        const item = this.formList[index];
        this.$set(this.formValue, item.key, item.defaultValue || "");
      }
    },
    onSubmit(values) {
      if (this.needRead && !this.read) return Toast(this.$t("hint.read"));
      this.$emit("submit", this.formValue, this.read);
    },
    showPassword(item, index) {
      let type = "password";
      if (item.type === "password") {
        type = "text";
      }
      this.formList[index].type = type;
    },
    handleClose() {
      for (const key in this.formValue) {
        if (Object.hasOwnProperty.call(this.formValue, key)) {
          this.formValue[key] = "";
        }
      }
      this.$emit("cancel");
    },
  },
};
</script>

<style lang="less" scoped>
.login_form_wrap {
  width: 90%;
  margin: calc(20rem / 16) auto 0px auto;

  .no_code {
    font-size: calc(14rem / 16);
    padding: 0 calc(5rem / 16);
    color: var(--textColor);
  }

  .form_list {
    border-radius: calc(5rem / 16);
    padding: calc(10rem / 16) calc(10rem / 16);

    .iconfont {
      font-size: calc(20rem / 16);
      margin-right: calc(5rem / 16);
      color: #c0c4cc;
    }

    .icon {
      font-size: calc(24rem / 16);
      margin-right: calc(5rem / 16);
      color: #3E8597;
    }

    /deep/ .van-field__left-icon {
      display: flex;
      align-items: center;
    }

    .img {
      width: calc(24rem / 16);
      height: calc(24rem / 16);
      margin-right: calc(5rem / 16);
      display: block;
    }

    /deep/ .van-field__control:disabled {
      color: var(--gray2);
      -webkit-text-fill-color: var(--gray2);
    }

    /deep/ .van-cell {
      background-color: var(--light);
      padding: calc(6rem / 16) calc(9rem / 16);
      margin-bottom: calc(15rem / 16);
      line-height: calc(32rem / 16);
      border-radius: calc(10rem / 16);

      &::after {
        border: none;
      }

      input::placeholder {
        line-height: calc(32rem / 16);
        color: var(--colors);
        font-size: calc(15rem / 16);
      }

      input {
        line-height: calc(32rem / 16);
        color: var(--textColor);
        font-size: calc(15rem / 16);
      }
    }

    /deep/ .van-checkbox {
      margin-top: calc(5rem / 16);
      margin-bottom: calc(8rem / 16);

      .van-icon {
        border-radius: 2px;
      }

      .van-checkbox__label {
        color: var(--light);
        font-size: calc(14rem / 16);
      }
    }

    .btns {
      margin: calc(10rem / 16) 0;

      .confirm {
        border: none;
        height: 40px;
        background: #118799;
        border: none;
        border-radius: calc(3rem / 16);
        color: #fff;
        font-weight: 700;
        font-size: calc(16rem / 16);
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center
      }
    }
  }

  .btn_wrap {
    font-size: calc(14rem / 16);
    color: var(--light);
    margin-top: calc(20rem / 16);
    text-align: center;
  }
}
</style>