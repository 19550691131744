<template>
  <div class="wrap">
    <Navbar :title="$t('Login.registrasi')" fixed :border="false" />
    <div class="register">
      <div class="register_logo"></div>
      <div class="form_wrap_bar">
        <LoginForm :formList="formList" cancelText="Login.Forgot.goBack" confirmText="Login.Forgot.registerTitle"
          readText="login.rule" :needRead="true" @cancel="handleClose" @submit="onSubmit">
        </LoginForm>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon, Image as VImg, Toast } from "vant";
import Navbar from "@/components/JNav";
import LoginForm from "./components/form.vue";
import { SEND_SMS_CODE, LOGIN } from "@/api";
import { getNewPhone } from "@/utils/tools";
export default {
  components: {
    Icon,
    VImg,
    LoginForm,
    Navbar
  },
  data() {
    return {
      formList: [{
        key: "userphone",
        flag: true,
        icon: "contact",
        label: "Login.nameInput",
        rules: [{ required: false, message: this.$t("hint.usernameEnter") }],
      }, {
        label: "Login.Daftar.password",
        type: "password",
        key: "userpass",
        icon: "lock",
        flag: true,
        rules: [{ required: false, message: this.$t("hint.passwordEnter") }],
        isPassword: true,
      }, {
        label: "Login.Daftar.againPassword",
        type: "password",
        key: "againPassword",
        icon: "lock",
        flag: false,
        rules: [
          { required: false, message: this.$t("hint.passwordAgainEnter") },
        ],
        isPassword: true,
      }, {
        label: "Login.Daftar.referrer",
        type: "text",
        key: "fromid",
        flag: true,
        img: require('../../assets/new/login/formId.png'),
        rules: [{ required: false, message: this.$t("hint.formidEnter") }],
      }],
    };
  },
  methods: {
    onSubmit(values) {
      if (!values.userphone) return this.$toast(this.$t('Login.nameInput'));
      if (!values.userpass) return this.$toast(this.$t('hint.passwordEnter'));
      if (!values.againPassword) return this.$toast(this.$t('hint.passwordAgainEnter'));
      Toast.loading({ duration: 0 });
      const { againPassword, ...useValues } = values;
      if (againPassword === useValues.userpass) {
        if (!this.formList.find((item) => item.key === "code")) {
          useValues.code = "112233";
        }
        LOGIN(useValues)
          .then((r) => {
            if (r.data.ret === 1) {
              this.$cookie.set("userInfo", JSON.stringify(r.data.data));
              this.$cookie.set("token", r.data.data.token);
              if (this.$cookie.get("fromid")) {
                this.$cookie.remove("fromid");
              }
              this.$store.dispatch("getSystemConfig");
              this.$router.replace("goods");
            } else {
              Toast.clear();
              Toast(r.data.msg);
            }
          }).catch((e) => {
            Toast.clear();
            console.log(e);
          });
        return;
      }
      Toast(this.$t("Hint.pwdInconformity"));
    },
    handleClose() {
      this.$router.replace({ name: "login" });
    },
  },
};
</script>

<style lang="less" scoped>
.wrap {
  background: var(--bg);
  min-height: 100vh;
  background: url('../../assets/new/login/login_bg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-sizing: border-box;

  /deep/ .van-nav-bar {
    background-color: transparent;
  }

  .register {
    &_logo {
      margin: auto;
      width: calc(195rem / 16);
      height: calc(120rem / 16);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50%;
      background-image: url('../../assets/new/login/logo.png');
      position: relative;
      z-index: 1;
      margin-top: calc(20rem / 16);
    }

    .form_wrap_bar {
      color: #333;
    }
  }

}
</style>